import { Application, CreatedApplication } from "models/AdmandevAuth/Application";
import { RequestResponse } from "models/SoireeDefis/requests/RequestResponse";
import { AxiosConfigurator } from "services/AxiosConfigurator";

const axios = AxiosConfigurator.getAxiosInstance("aa/app");

/**
 * Defines the AdmandevAuth authorized application service.
 */
export class AAApplicationService {
	/**
	 * Get all authorized applications
	 * 
	 * @returns {Promise<RequestResponse<Application[]>>} The applications list
	 */
	public static async getAll () {
		const response = await axios.get<RequestResponse<Application[]>>("/");
		return response.data;
	}

	/**
	 * Create a new authorized application
	 * 
	 * @param {Application} application The application to create
	 * @returns {Promise<RequestResponse<CreatedApplication>>} The created application
	 */
	public static async create (application: Application) {
		const response = await axios.post<RequestResponse<CreatedApplication>>("/", {
			applicationName: application.name,
			appAddress: application.appAddress,
			activateAccountRedirectUrl: application.activateAccountRedirectUrl,
		});
		return response.data;
	}

	/**
	 * Update an authorized application
	 * 
	 * @param {string} appId The application id to update
	 * @param {Application} application The application changes
	 * @returns {Promise<RequestResponse<Application>>} The updated application
	 */
	public static async update (appId: string, application: Application) {
		const response = await axios.put<RequestResponse<Application>>(`/${appId}`, {
			appAddress: application.appAddress,
			activateAccountRedirectUrl: application.activateAccountRedirectUrl,
			isEnabled: application.enabled,
		});
		return response.data;
	}

	/**
	 * Delete an authorized application
	 * 
	 * @param {string} appId The application id to delete
	 * @returns {Promise<RequestResponse<null>>} The ressponse
	 */
	public static async delete (appId: string) {
		const response = await axios.delete<RequestResponse<null>>(`/${appId}`);
		return response.data;
	}
}