/** 
 * Defines utilities methods 
 */
export class Utils {
	/** 
	 * Export and download data
	 * 
	 * @param {string} fileData Content to export
	 * @param {string} fileName The default file name
	 */
	static exportData (fileData: string, fileName: string) {
		const blob = new Blob([fileData], { type: "application/json" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.download = fileName;
		link.href = url;
		link.click();
	}
}