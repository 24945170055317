import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import { Challenge, ChallengeType } from "models/SoireeDefis/Challenge";

interface IProps {
	isOpen: boolean;
	onClose: VoidFunction;
	onSubmit: (challenge: Challenge) => void;
	initialValue?: Challenge;
	title?: string;
	hideOptional?: boolean;
}

function ChallengeFormDialog ({ isOpen, onClose, onSubmit, initialValue, title, hideOptional }: IProps) {
	const [description, setDescription] = useState("");
	const [type, setType] = useState(ChallengeType.Solo);
	const [categories, setCategories] = useState<string[]>([]);
	const [winPoints, setWinPoints] = useState(1);
	const [loosePoints, setLoosePoints] = useState(1);
	const [minuteTime, setMinuteTime] = useState(1);

	const [enableMinuteTime, setEnableMinuteTime] = useState(false);

	useEffect(() => {
		setToInititalValue();
	}, [initialValue]);

	useEffect(() => {
		if (isOpen) {
			if (initialValue) {
				setToInititalValue();
			} else {
				resetForm();
			}
		}
	}, [isOpen]);

	const setToInititalValue = () => {
		if (initialValue) {
			setDescription(initialValue.description);
			setType(initialValue.type);
			setCategories(initialValue.categories.map(c => c.name));
			setWinPoints(initialValue.winPoints);
			setLoosePoints(initialValue.loosePoints);
			setMinuteTime(initialValue.minuteTime ?? 1);
			setEnableMinuteTime(initialValue.minuteTime !== undefined);
		}
	};

	const resetForm = () => {
		setDescription("");
		setType(ChallengeType.Solo);
		setCategories([]);
		setWinPoints(1);
		setLoosePoints(1);
		setMinuteTime(1);
		setEnableMinuteTime(false);
	};

	const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);
	const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => setType(e.target.value as ChallengeType);
	const handleWinPointsChange = (e: React.ChangeEvent<HTMLInputElement>) => setWinPoints(parseInt(e.target.value));
	const handleMinuteTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => setMinuteTime(parseInt(e.target.value));
	const handleToogleMinuteTime = (e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
		setEnableMinuteTime(isChecked);
	};
	const handleLoosePointsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLoosePoints(parseInt(e.target.value));
	};
	const handleCategoryToggle = (e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
		const category = e.target.id;
		if (isChecked) {
			setCategories(categories => [...categories, category]);
		} else {
			setCategories(categories => categories.filter(c => c !== category));
		}
	};

	const isValidForm = () => {
		return description.length > 0
			&& type
			&& categories.length > 0
			&& winPoints > 0
			&& loosePoints > 0;
	};

	const handleSubmit = () => {
		if (isValidForm()) {
			const challenge: Challenge = {
				_id: "1",
				description,
				type,
				categories: categories.map(c => ({
					_id: "1",
					name: c,
					level: 0
				})),
				winPoints,
				loosePoints,
				minuteTime: enableMinuteTime ? minuteTime : undefined
			};

			onSubmit(challenge as Challenge);
		}
	};

	return (
		<Dialog maxWidth="xs" fullWidth open={isOpen} onClose={onClose}>
			<DialogTitle>{ title ?? "Nouveau défi" }</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<FormControl>
						<FormLabel>Description</FormLabel>
						<TextField
							autoFocus
							placeholder="Description du défi"
							fullWidth
							multiline
							minRows={2}
							value={description}
							onChange={handleDescriptionChange}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>Type de défi</FormLabel>
						<RadioGroup
							row
							value={type}
							onChange={handleTypeChange}
						>
							{Object.values(ChallengeType).map(type => (
								<FormControlLabel
									key={type}
									value={type}
									label={type}
									control={<Radio />}
								/>
							))}
						</RadioGroup>
					</FormControl>
					<FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
						<FormLabel component="legend">Catégories</FormLabel>
						<FormGroup row>
							{["Soft", "Normal", "Hard"].map(c => (
								<FormControlLabel
									key={c}
									label={c}
									control={
										<Checkbox
											id={c}
											checked={categories?.includes(c)}
											onChange={handleCategoryToggle}
										/>
									}
								/>
							))}
						</FormGroup>
					</FormControl>
					<Collapse in={!hideOptional}>
						<Stack spacing={2} direction="row" justifyContent="space-between">
							<FormControl>
								<FormLabel>Bonus</FormLabel>
								<TextField
									type="number"
									placeholder="Bonus"
									InputProps={{ inputProps: { min: 1 } }}
									fullWidth
									value={winPoints}
									onChange={handleWinPointsChange}
								/>
							</FormControl>
							<FormControl>
								<FormLabel>Malus</FormLabel>
								<TextField
									type="number"
									placeholder="Malus"
									InputProps={{ inputProps: { min: 1 } }}
									fullWidth
									value={loosePoints}
									onChange={handleLoosePointsChange}
								/>
							</FormControl>
						</Stack>
						<FormControl sx={{ mt: 2 }} component="fieldset" variant="standard">
							<FormGroup>
								<FormControlLabel
									label="Temps imparti"
									control={
										<Checkbox
											value={enableMinuteTime}
											onChange={handleToogleMinuteTime}
										/>
									}
								/>
								<Collapse in={enableMinuteTime}>
									<TextField
										type="number"
										InputProps={{ inputProps: { min: 1 } }}
										fullWidth
										value={minuteTime}
										onChange={handleMinuteTimeChange}
									/>
								</Collapse>
							</FormGroup>
						</FormControl>
					</Collapse>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Annuler</Button>
				<Button disabled={!isValidForm()} onClick={handleSubmit}>Valider</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ChallengeFormDialog;