import React from "react";
import { CircularProgress, Stack, Typography, Paper } from "@mui/material";

function LoadingPage () {
	return (
		<Paper elevation={0} sx={{ height: "100%" }}>
			<Stack spacing={2} justifyContent="center" alignItems="center" height="100%">
				<CircularProgress />
				<Typography variant="h6">Chargement...</Typography>
			</Stack>
		</Paper>
	);
}

export default LoadingPage;