import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import { LooserChallenge } from "models/SoireeDefis/LooserChallenge";

interface IProps {
	isOpen: boolean;
	onClose: VoidFunction;
	onSubmit: (challenge: LooserChallenge) => void;
	initialValue?: LooserChallenge;
}

function LooserChallengeFormDialog ({ isOpen, onClose, onSubmit, initialValue }: IProps) {
	const [description, setDescription] = useState("");
	const [levelName, setLevelName] = useState("");

	useEffect(() => {
		setToInititalValue();
	}, [initialValue]);

	useEffect(() => {
		if (isOpen) {
			if (initialValue) {
				setToInititalValue();
			} else {
				resetForm();
			}
		}
	}, [isOpen]);

	const setToInititalValue = () => {
		if (initialValue) {
			setDescription(initialValue.description);
			setLevelName(initialValue.levelName);
		}
	};

	const resetForm = () => {
		setDescription("");
		setLevelName("");
	};

	const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);
	const handleLevelNameChange = (e: SelectChangeEvent<string>) => setLevelName(e.target.value);

	const isValidForm = () => {
		return description !== "" && levelName !== "";
	};

	const handleSubmit = () => {
		if (isValidForm()) {
			const challenge: LooserChallenge = {
				_id: "",
				level: 0,
				description,
				levelName,
			};

			onSubmit(challenge);
		}
	};

	return (
		<Dialog maxWidth="xs" fullWidth open={isOpen} onClose={onClose}>
			<DialogTitle>{initialValue ? "Modifier" : "Ajouter"} un gage</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<FormControl>
						<FormLabel>Description</FormLabel>
						<TextField
							autoFocus
							placeholder="Description du gage"
							fullWidth
							multiline
							minRows={2}
							value={description}
							onChange={handleDescriptionChange}
						/>
					</FormControl>
					<FormControl fullWidth>
						<FormLabel>Catégorie</FormLabel>
						<Select
							value={levelName}
							onChange={handleLevelNameChange}
						>
							<MenuItem value="Soft">Soft</MenuItem>
							<MenuItem value="Normal">Normal</MenuItem>
							<MenuItem value="Hard">Hard</MenuItem>
						</Select>
					</FormControl>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Annuler</Button>
				<Button disabled={!isValidForm()} onClick={handleSubmit}>Valider</Button>
			</DialogActions>
		</Dialog>
	);
}

export default LooserChallengeFormDialog;