import React, { useState, useEffect } from "react";
import { Button, Container, Paper, Divider, Typography, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../components/Provider/AuthProvider";
import Page from "components/Page/Page";
import { useFeedback } from "components/Provider/Feedback/FeedbackProvider";

interface LocationState {
	from?: string;
}

function LoginPage () {
	const [login, setLogin] = useState("");
	const [psw, setPsw] = useState("");

	const { openAlert } = useFeedback();
	const navigate = useNavigate();
	const location = useLocation();
	const { signin, isLogged } = useAuth();

	useEffect(() => {
		if (isLogged) {
			let to = "/";
			if (location.state) {
				const { from } = location.state as LocationState;
				if (from) {
					to = from;
				}
			}
			navigate(to);
		}
	}, [isLogged]);

	const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLogin(e.target.value);
	};

	const handlePswChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPsw(e.target.value);
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		signin(login, psw)
			.catch(err => {
				openAlert("error", err);
			});
	};

	return (
		<Page>
			<Container maxWidth="xs">
				<Paper sx={{ p: 2 }}>
					<Typography variant="h5">Connexion</Typography>
					<Divider sx={{ my: 2 }} />
					<form onSubmit={handleSubmit}>
						<TextField
							type="text"
							name="login"
							label="Votre identifiant..."
							value={login}
							margin="normal"
							autoFocus
							required
							fullWidth
							onChange={handleLoginChange}
						/>
						<TextField
							type="password"
							name="psw"
							label="Votre mot de passe..."
							value={psw}
							margin="normal"
							required
							fullWidth
							onChange={handlePswChange}
						/>
						<Button
							type="submit"
							variant="contained"
							fullWidth
							sx={{ mt: 2 }}
						>
            Se connecter
						</Button>
					</form>
				</Paper>
			</Container>
		</Page>
	);
}

export default LoginPage;
