import React, { useEffect, useState } from "react";
import { MenuItem, ListItemIcon, Avatar, Badge } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VerifiedIcon from "@mui/icons-material/Verified";
import Page from "components/Page/Page";
import { Account } from "models/AdmandevAuth/Account";
import { useFeedback } from "components/Provider/Feedback/FeedbackProvider";
import { AAAccountService } from "services/api/AdmandevAuth/AAAcountService";
import EnhancedTable, { HeadCell } from "components/Table";
import IconMenu from "components/IconMenu";

function AAAcountsPage () {
	const [accounts, setAccounts] = useState<Account[]>([]);

	const { openAlert, openConfirmDialog } = useFeedback();

	useEffect(() => {
		fetchAccounts();
	}, []);

	const fetchAccounts = async () => {
		try {
			const result = await AAAccountService.getAll();

			if (result.isError) {
				throw result.message;
			}

			setAccounts(result.value.reverse());

		} catch (error) {
			openAlert("error", error as string);
		}
	};

	const handleToggleAccount = async (account: Account) => {
		openConfirmDialog({
			title: account.enabled ? "Désactiver le compte" : "Activer le compte",
			msg: `Êtes-vous sûr de vouloir ${account.enabled ? "désactiver" : "activer"} le compte de ${account.name}?`,
			onConfirm: async () => {
				try {
					const result = await AAAccountService.toggleAccount(account.userId);

					if (result.isError) {
						throw result.message;
					}

					openAlert("success", `Le compte bien a été ${account.enabled ? "désactivé" : "activé"}.`);

					const accountsList = [...accounts];
					const accountIndex = accountsList.findIndex(a => a.userId === account.userId);
					accountsList[accountIndex].enabled = !account.enabled;
					setAccounts(accountsList);

				} catch (error) {
					openAlert("error", error as string);
				}
			}
		});
	};

	const tableColumns: HeadCell[] = [
		{
			id: "pictureUrl",
			label: "",
			render: (row: Account) => (
				<Badge
					overlap="circular"
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					badgeContent={
						row.enabled && <VerifiedIcon color="success" />
					}
				>
					<Avatar src={row.pictureUrl} />
				</Badge>
			)
		},
		{
			id: "name",
			label: "Utilisateur"
		},
		{
			id: "email",
			label: "Email"
		},
		{
			id: "isAdmin",
			label: "Administateur",
			render: (row: Account) => row.isAdmin ? "Oui" : "Non"
		},
		{
			id: "action-menu",
			label: "",
			numeric: true,
			render: (row: Account) => (
				<IconMenu
					id={`challenge-item-menu-${row.name}`}
					icon={<MoreVertIcon />}
				>
					<MenuItem divider onClick={() => handleToggleAccount(row)}>
						<ListItemIcon>
							<VerifiedIcon />
						</ListItemIcon>
						{row.enabled ? "Désactiver" : "Activer"}
					</MenuItem>
				</IconMenu>
			),
		}
	];

	return (
		<Page>
			<EnhancedTable
				title="Comptes"
				columns={tableColumns}
				data={accounts}
				rowIdentifier={(row: Account) => row.userId}
				toolAction={<div />}
			/>
		</Page>
	);
}

export default AAAcountsPage;