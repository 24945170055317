import React from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { ChallengeProposal } from "models/SoireeDefis/ChallengeProposal";
import { Category } from "models/SoireeDefis/Challenge";

interface IProps {
	item: ChallengeProposal;
	onDelete: (proposalId: string) => void;
	onPublish: (proposal: ChallengeProposal) => void,
}

function SDProposalItem ({ item, onDelete, onPublish }: IProps) {
	const { challenge } = item;

	const handleDelete = () => {
		onDelete(item._id);
	};

	const handlePublish = () => {
		onPublish(item);
	};

	const getCategoryView = (value: Category) => {
		let color = "";
		switch (value.name) {
			case "Soft":
				color = "darkgreen";
				break;

			case "Normal":
				color = "darkorange";
				break;

			case "Hard":
				color = "darkred";
				break;

			default:
				color = "black";
				break;
		}

		return (
			<Box key={value.name} bgcolor={color} py={0.5} px={1} borderRadius={4}>
				<Typography fontWeight="bold">{value.name}</Typography>
			</Box>
		);
	};

	return (
		<Paper elevation={2}>
			<Stack spacing={2} p={2}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography fontWeight="bold">{challenge.type}</Typography>
					<Stack direction="row" spacing={1}>
						{challenge.categories.map(getCategoryView)}
					</Stack>
				</Stack>
				<Divider />
				<Box>
					<Typography textAlign="center">{challenge.description}</Typography>
				</Box>
				<Stack direction="row" justifyContent="space-between">
					<Button color="error" onClick={handleDelete}>Supprimer</Button>
					<Button onClick={handlePublish}>Publier</Button>
				</Stack>
			</Stack>
		</Paper>
	);
}

export default SDProposalItem;