import React from "react";
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from "@mui/material";

interface IProps {
	isOpen: boolean;
	title: string;
	message: string;
	content?: JSX.Element;
	onClose: VoidFunction;
}

function AlertDialog ({ isOpen, title, message, content, onClose }: IProps) {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{
					content ? content : <DialogContentText>{message}</DialogContentText>
				}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>OK</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AlertDialog;