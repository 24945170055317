import React, { useState, useEffect } from "react";
import { AuthService } from "services/api/AuthService";
import { User } from "models/User";

interface IProps {
	children: React.ReactNode
}

interface AuthContextType {
	user?: User;
	isLogged: boolean;
	firstLoginLoading: boolean;
	signin: (login: string, psw: string) => Promise<void>;
	logout: VoidFunction;
}

const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider ({ children }: IProps) {
	const [user, setUser] = useState<User>();
	const [isLogged, setIsLogged] = useState(false);
	const [firstLoginLoading, setFirstLoginLoading] = useState(true);

	useEffect(() => {
		handleSigninWithToken();
	}, []);

	const handleSignin = async (login: string, psw: string) => {
		try {
			const response = await AuthService.login(login, psw);
			if (!response.isError) {
				setUser(response.value);
				setIsLogged(true);
			}
		} finally {
			setFirstLoginLoading(false);
		}
	};

	const handleSigninWithToken = async () => {
		try {
			const response = await AuthService.loginWithCookie();
			if (!response.isError) {
				setUser(response.value);
				setIsLogged(true);
			}
		} finally {
			setFirstLoginLoading(false);
		}
	};

	const handleLogout = async () => {
		const response = await AuthService.logout();
		if (!response.isError) {
			setUser(undefined);
			setIsLogged(false);
			window.location.reload();
		}
	};

	const value: AuthContextType = {
		user,
		isLogged,
		firstLoginLoading,
		signin: handleSignin,
		logout: handleLogout,
	};

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuth () {
	return React.useContext(AuthContext);
}

export function useUser () {
	const auth = useAuth();
	return auth.user;
}

export function useIsLogged () {
	const auth = useAuth();
	return auth.isLogged;
}
