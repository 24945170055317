import { RequestResponse } from "models/SoireeDefis/requests/RequestResponse";
import { ChallengeProposal } from "models/SoireeDefis/ChallengeProposal";
import { AxiosConfigurator } from "services/AxiosConfigurator";
import { Challenge } from "models/SoireeDefis/Challenge";

const axios = AxiosConfigurator.getAxiosInstance("sd/proposal");

/** 
 * Defines the Soirée défis challenge proposal service 
 */
export class SDProposalService {
	/** 
	 * Get all proposals
	 * 
	 * @returns {Promise<RequestResponse<ChallengeProposal[]>>} The proposal list 
	 */
	public static async getAll () {
		const response = await axios.get<RequestResponse<ChallengeProposal[]>>("/");
		return response.data;
	}

	/** 
	 * Create a proposal
	 * 
	 * @param {Challenge} challenge The challenge to propose
	 * @returns {Promise<RequestResponse<ChallengeProposal>>} The created proposal 
	 */
	public static async create (challenge: Challenge) {
		const response = await axios.post<RequestResponse<ChallengeProposal>>("/", {
			...challenge,
			categories: challenge.categories.map(c => c.name)
		});
		return response.data;
	}

	/** 
	 * Delete a proposal
	 * 
	 * @param {string} id The proposal Id
	 * @returns {Promise<RequestResponse<null>>} The response 
	 */
	public static async delete (id: string) {
		const response = await axios.delete<RequestResponse<null>>(`/${id}`);
		return response.data;
	}

	/** 
	 * Publish a proposal
	 * 
	 * @param {string} id The proposal Id
	 * @param {Challenge} challenge The challenge to publish
	 * @returns {Promise<RequestResponse<ChallengeProposal>>} The published proposal
	 */
	public static async publish (id: string, challenge: Challenge) {
		const response = await axios.post<RequestResponse<ChallengeProposal>>(`/${id}`, {
			...challenge,
			categories: challenge.categories.map(c => c.name)
		});
		return response.data;
	}
}