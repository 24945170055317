export interface Category {
	_id: string;
	name: string;
	level: number;
}

export enum ChallengeType {
	Solo = "Solo",
	VS = "VS",
	Thief = "Thief",
	Team = "Team",
}

export interface Challenge {
	_id: string;
	description: string;
	type: ChallengeType;
	categories: Category[];
	winPoints: number;
	loosePoints: number;
	minuteTime?: number;
}