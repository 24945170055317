import React, { useEffect, useState } from "react";
import { Grid, Stack, Tooltip, IconButton, Typography, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Page from "components/Page/Page";
import { Application } from "models/AdmandevAuth/Application";
import { useFeedback } from "components/Provider/Feedback/FeedbackProvider";
import { AAApplicationService } from "services/api/AdmandevAuth/AAApplicationService";
import PageHeader from "components/Page/PageHeader";
import AppItem from "./AppItem";
import { useDisclose } from "hooks/discloseHook";
import AppFormDialog from "./AppFormDialog";

function ApplicationsPage () {
	const [apps, setApps] = useState<Application[]>([]);
	const [appToEdit, setAppToEdit] = useState<Application>();
	const [isAppFormOpen, openAppForm, closeAppForm] = useDisclose(false);

	const { openAlert, openConfirmDialog, openAlertDialog } = useFeedback();

	useEffect(() => {
		fetchApplications();
	}, []);

	const fetchApplications = async () => {
		try {
			const result = await AAApplicationService.getAll();

			if (result.isError) {
				throw result.message;
			}

			setApps(result.value.reverse());

		} catch (error) {
			openAlert("error", error as string);
		}
	};

	const handleOpenNewAppForm = () => {
		setAppToEdit(undefined);
		openAppForm();
	};

	const handleOpenEditAppForm = (app: Application) => {
		setAppToEdit(app);
		openAppForm();
	};

	const handleSubmitNewApp = async (app: Application) => {
		try {
			const result = await AAApplicationService.create(app);
			if (result.isError) {
				throw result.message;
			}

			fetchApplications();
			closeAppForm();

			openAlertDialog({
				title: "Application créée",
				msg: "",
				children: getSuccesCreationView(result.value.publicKey)
			});

		} catch (error: unknown) {
			openAlert("error", error as string);
		}
	};

	const handleEditApp = async (app: Application) => {
		if (!appToEdit) {
			return;
		}

		try {
			const result = await AAApplicationService.update(appToEdit._id, app);

			if (result.isError) {
				throw result.message;
			}

			closeAppForm();

			const newApps = [...apps];
			const index = newApps.findIndex(a => a._id === appToEdit._id);
			newApps[index] = result.value;
			setApps(newApps);

			openAlert("success", "L'application a été modifiée !");

		} catch (error: unknown) {
			openAlert("error", error as string);
		}
	};

	const handleDeleteApp = (appId: string, appName: string) => {
		const process = async () => {
			try {
				const result = await AAApplicationService.delete(appId);

				if (result.isError) {
					throw result.message;
				}

				setApps(apps.filter(app => app._id !== appId));
				openAlert("success", "L'application a été supprimée avec succès !");

			} catch (error: unknown) {
				openAlert("error", error as string);
			}
		};

		openConfirmDialog({
			title: `Supprimer ${appName}`,
			msg: "Êtes-vous sûr de vouloir supprimer cette application ?",
			onConfirm: process
		});
	};

	const getSuccesCreationView = (publicToken: string) => {
		const handleCopyToken = () => {
			navigator.clipboard.writeText(publicToken);
			openAlert("success", "Le jeton a été copié !");
		};

		return (
			<Stack spacing={2}>
				<Typography variant="body1">{"L'application a été créée avec succès !"}</Typography>
				<TextField
					label="Votre jeton public"
					value={publicToken}
					fullWidth
					InputProps={{
						readOnly: true,
						endAdornment: (
							<Tooltip title="Copier">
								<IconButton onClick={handleCopyToken}>
									<ContentCopyIcon />
								</IconButton>
							</Tooltip>
						)
					}}
				/>
			</Stack>
		);
	};

	return (
		<Page>
			<PageHeader
				title="Applications autorisées"
				tools={
					<>
						<Tooltip title="Nouvelle application">
							<IconButton onClick={handleOpenNewAppForm}>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</>
				}
			/>
			<Grid container spacing={2}>
				{
					apps.map(app => (
						<AppItem
							key={app._id}
							item={app}
							onEdit={handleOpenEditAppForm}
							onDelete={handleDeleteApp}
						/>
					))
				}
			</Grid>
			<AppFormDialog
				isOpen={isAppFormOpen}
				initialValue={appToEdit}
				onClose={closeAppForm}
				onSubmit={!appToEdit ? handleSubmitNewApp : handleEditApp}
			/>
		</Page>
	);
}

export default ApplicationsPage;