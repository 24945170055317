import React from "react";
import LoadingPage from "pages/Admanoffice/Loading/LoadingPage";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../Provider/AuthProvider";

interface IProps {
	children: React.ReactElement;
}

function ProtectedRoute ({ children }: IProps) {
	const { isLogged, firstLoginLoading } = useAuth();
	const location = useLocation();

	if (!isLogged && firstLoginLoading) {
		return <LoadingPage />;
	}

	if (!isLogged) {
		return <Navigate to="/login" state={{ from: location.pathname }} />;
	}

	return children;
}

export default ProtectedRoute;
