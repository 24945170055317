import { useState } from "react";

/**
 * Hook to open, close, toogle a thing
 * 
 * @param {boolean} initialValue The initial value
 * @returns {[boolean, () => void, () => void, () => void]} isOpen, open, close, toggle
 */
export function useDisclose (initialValue: boolean) {
	const [isOpen, setIsOpen] = useState(initialValue);

	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);
	const toggle = () => setIsOpen(value => !value);

	return [
		isOpen,
		open,
		close,
		toggle
	] as const;
}