import React from "react";
import { Grid, Stack, MenuItem, ListItemIcon, Typography, Card, CardContent } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconMenu from "components/IconMenu";
import { Application } from "models/AdmandevAuth/Application";

interface IProps {
	item: Application;
	onEdit: (app: Application) => void;
	onDelete: (id: string, name: string) => void;
}

function AppItem ({ item, onEdit, onDelete }: IProps) {
	const handleEdit = () => onEdit(item);
	const handleDelete = () => onDelete(item._id, item.name);

	return (
		<Grid item>
			<Card elevation={3}>
				<CardContent>
					<Stack spacing={2} width={250}>
						<Stack direction="row" alignItems="center" justifyContent="space-between">
							<Stack spacing={1} direction="row" alignItems="center">
								<CircleIcon color={item.enabled ? "success" : "error"} />
								<Typography variant="h6">{item.name}</Typography>
							</Stack>
							<IconMenu
								id={`app-item-menu-${item._id}`}
								icon={<MoreVertIcon />}
							>
								<MenuItem onClick={handleEdit}>
									<ListItemIcon>
										<EditIcon />
									</ListItemIcon>
                                Editer
								</MenuItem>
								<MenuItem onClick={handleDelete}>
									<ListItemIcon>
										<DeleteIcon color="error" />
									</ListItemIcon>
									<Typography fontWeight="bold" color="red">Supprimer</Typography>
								</MenuItem>
							</IconMenu>
						</Stack>
					</Stack>
				</CardContent>
			</Card>
		</Grid>
	);
}

export default AppItem;