import { RequestResponse } from "models/SoireeDefis/requests/RequestResponse";
import { Account } from "models/AdmandevAuth/Account";
import { AxiosConfigurator } from "services/AxiosConfigurator";

const axios = AxiosConfigurator.getAxiosInstance("aa/account");

/**
 * Defines the Admandev auth account service
 */
export class AAAccountService {
	/**
	 * Get all accounts
	 * 
	 * @returns {Promise<RequestResponse<Account[]>>} The accounts list
	 */
	public static async getAll () {
		const response = await axios.get<RequestResponse<Account[]>>("/");
		return response.data;
	}

	/**
	 * Toggle an account
	 * 
	 * @param {string} id The account id to toggle
	 * @returns {Promise<RequestResponse<null>>} The  response
	 */
	public static async toggleAccount (id: string) {
		const response = await axios.get<RequestResponse<null>>("/toggle/" + id);
		return response.data;
	}
}