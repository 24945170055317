import { User } from "models/User";
import { RequestResponse } from "../../models/SoireeDefis/requests/RequestResponse";
import { AxiosConfigurator } from "../AxiosConfigurator";

const axios = AxiosConfigurator.getAxiosInstance("auth");

/** 
 * Defines the auth service 
 */
export class AuthService {
	/** 
	 * Signin user
	 * 
	 * @param {string} login The user login
	 * @param {string} psw The user password
	 * @returns {Promise<RequestResponse<User>>} The response
	 */
	static async login (login: string, psw: string) {
		const response = await axios.post<RequestResponse<User>>("/login", {
			login,
			psw
		});
		return response.data;
	}

	/** 
	 *  Signin with cookie
	 *
	 * @returns {Promise<RequestResponse<User>>} The response
	 */
	static async loginWithCookie () {
		const response = await axios.get<RequestResponse<User>>("/loginwithcookie");
		return response.data;
	}

	/**
	 * Logout from the application
	 * 
	 * @returns {Promise<RequestResponse<null>>} The response
	 */
	static async logout () {
		const response = await axios.get<RequestResponse<null>>("/logout");
		return response.data;
	}
}
