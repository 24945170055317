import { LooserChallenge } from "models/SoireeDefis/LooserChallenge";
import { RequestResponse } from "models/SoireeDefis/requests/RequestResponse";
import { AxiosConfigurator } from "services/AxiosConfigurator";

const axios = AxiosConfigurator.getAxiosInstance("sd/looserchallenge");

/**
 * Defines the Soirée défis looser challenge service
 */
export class SDLooserChallengeService {
	/**
	 * Get all looser challenges
	 * 
	 * @returns {Promise<RequestResponse<LooserChallenge[]>>} The looser challenge list
	 */
	public static async getAll () {
		const response = await axios.get<RequestResponse<LooserChallenge[]>>("/");
		return response.data;
	}

	/**
	 * Create a new looser challenge
	 * 
	 * @param {string} description The description of the looser challenge
	 * @param {string} levelName The level name of the looser challenge
	 * @returns {Promise<RequestResponse<LooserChallenge>>} The created looser challenge
	 */
	public static async create (description: string, levelName: string) {
		const response = await axios.post<RequestResponse<LooserChallenge>>("/", {
			description,
			levelName
		});
		return response.data;
	}

	/**
	 * Update a looser challenge
	 * 
	 * @param {string} id The id of the looser challenge to update
	 * @param {string} description The description of the looser challenge
	 * @param {string} levelName The level name of the looser challenge
	 * @returns {Promise<RequestResponse<LooserChallenge>>} The updated looser challenge
	 */
	public static async update (id: string, description: string, levelName: string) {
		const response = await axios.put<RequestResponse<LooserChallenge>>(`/${id}`, {
			description,
			levelName
		});
		return response.data;
	}

	/**
	 * Delete a looser challenge
	 * 
	 * @param {string} id The id of the looser challenge to delete
	 * @returns {Promise<RequestResponse<LooserChallenge>>} The deleted looser challenge
	 */
	public static async delete (id: string) {
		const response = await axios.delete<RequestResponse<LooserChallenge>>(`/${id}`);
		return response.data;
	}
}