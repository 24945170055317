import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Application } from "models/AdmandevAuth/Application";

interface IProps {
	isOpen: boolean;
	onClose: VoidFunction;
	onSubmit: (app: Application) => void;
	initialValue?: Application;
}

function AppFormDialog ({ isOpen, onClose, onSubmit, initialValue }: IProps) {
	const [name, setName] = useState("");
	const [appAddress, setAppAddress] = useState("*");
	const [accountActivationUrl, setAccountActivationUrl] = useState("");
	const [enabled, setEnabled] = useState(true);

	const editMode = initialValue !== undefined;

	useEffect(() => {
		setToInititalValue();
	}, [initialValue]);

	useEffect(() => {
		if (isOpen) {
			if (initialValue) {
				setToInititalValue();
			} else {
				resetForm();
			}
		}
	}, [isOpen]);

	const setToInititalValue = () => {
		if (initialValue) {
			setName(initialValue.name);
			setAppAddress(initialValue.appAddress);
			setAccountActivationUrl(initialValue.activateAccountRedirectUrl);
			setEnabled(initialValue.enabled);
		}
	};

	const resetForm = () => {
		setName("");
		setAppAddress("*");
		setAccountActivationUrl("");
		setEnabled(true);
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
	const handleAppAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => setAppAddress(e.target.value);
	const handleAccountActivationUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAccountActivationUrl(e.target.value);
	};
	const handleEnabledChange = (e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => setEnabled(isChecked);

	const isValidForm = () => {
		return name !== ""
            && appAddress !== ""
            && accountActivationUrl !== "";
	};

	const handleSubmit = () => {
		if (isValidForm()) {
			const application: Application = {
				_id: initialValue?._id ?? "",
				name,
				appAddress,
				activateAccountRedirectUrl: accountActivationUrl,
				enabled,
			};
			onSubmit(application);
		}
	};

	return (
		<Dialog maxWidth="xs" fullWidth open={isOpen} onClose={onClose}>
			<DialogTitle>{editMode ? `Modifier ${name}` : "Créer une application"}</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					{
						!editMode && (
							<FormControl>
								<FormLabel>Nom</FormLabel>
								<TextField
									autoFocus
									type="text"
									placeholder="Nom de l'application"
									fullWidth
									value={name}
									onChange={handleNameChange}
								/>
							</FormControl>
						)
					}
					<FormControl>
						<FormLabel>{"Url de l'application (serveur)"}</FormLabel>
						<TextField
							type="text"
							placeholder="Url de l'application (serveur))"
							fullWidth
							value={appAddress}
							onChange={handleAppAddressChange}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{"Url d'activation de compte"}</FormLabel>
						<TextField
							type="text"
							placeholder="Url d'activation de compte"
							fullWidth
							value={accountActivationUrl}
							onChange={handleAccountActivationUrlChange}
						/>
					</FormControl>
					{
						editMode && (
							<FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
								<FormControlLabel
									label="Activer l'application"
									control={
										<Checkbox
											value={enabled}
											onChange={handleEnabledChange}
										/>
									}
								/>
							</FormControl>
						)
					}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Annuler</Button>
				<Button disabled={!isValidForm()} onClick={handleSubmit}>Valider</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AppFormDialog;