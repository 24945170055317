import axios, { AxiosInstance, AxiosResponse } from "axios";
import { RequestResponse } from "models/SoireeDefis/requests/RequestResponse";

const apiUrl = process.env.REACT_APP_SERVER_URL;

/** 
 * Defines the Axios configurator 
 */
export class AxiosConfigurator {
	/**
	 * Gets a configured Axios instance
	 * 
	 * @param {string} endPoint The service api end point
	 * @returns {AxiosInstance} The Axios instance
	 */
	static getAxiosInstance (endPoint: string) {
		const instance = axios.create({
			baseURL: `${apiUrl}/${endPoint}`,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*; charset=utf-8"
			},
		});

		instance.interceptors.response.use(
			(response: AxiosResponse) => response,
			(({ response }: { response: AxiosResponse<RequestResponse<null>> }) => {
				if (!response) {
					return Promise.reject("Le serveur ne répond pas !");
				}

				if (response?.data?.isError) {
					return Promise.reject(response.data.message);
				} else {
					return Promise.reject("Le serveur ne répond pas !");
				}
			})
		);

		return instance;
	}
}
