import React from "react";
import { Stack, Typography } from "@mui/material";

interface IProps {
	title: string;
	tools?: React.ReactNode
}

function PageHeader ({ title, tools }: IProps) {
	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center">
			<Typography variant="h6" mb={2}>{title}</Typography>
			<Stack spacing={2} direction="row" alignItems="center">
				{tools}
			</Stack>
		</Stack>
	);
}

export default PageHeader;