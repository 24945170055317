import React from "react";
import { Grid, Stack, Avatar, Typography, Button, Card, CardContent, CardActions } from "@mui/material";
import { MenuItem } from "components/Page/Menu";
import { NavigateFunction } from "react-router-dom";
import { useDisclose } from "hooks/discloseHook";

interface IProps {
	item: MenuItem;
	navigate: NavigateFunction;
}

function OfficeItem ({ item, navigate }: IProps) {
	const [isHover, setOverTrue, setOverFalse] = useDisclose(false);

	const getPath = (menuItem: MenuItem) => {
		let path = menuItem.path;
		if (menuItem.subMenus && menuItem.subMenus.length > 0) {
			path = `${path}${getPath(menuItem.subMenus[0])}`;
		}

		return path;
	};

	const handleClick = () => {
		const path = getPath(item);
		navigate(path);
	};

	return (
		<Grid item>
			<Card
				elevation={3}
				sx={{
					cursor: "pointer",
					transform: `scale(${isHover ? 1.1 : 1})`,
					transition: "transform 0.5s ease-in-out"
				}}
				onMouseOver={setOverTrue}
				onMouseOut={setOverFalse}
				onClick={handleClick}>
				<CardContent>
					<Stack
						spacing={2}
						justifyContent="center"
						alignItems="center"
						width={200}
						py={3}
						px={2}
					>
						{
							typeof item.icon === "string" ? (
								<Avatar
									src={item.icon as string}
									sx={{
										width: 64,
										height: 64
									}}
								/>
							) : (
								React.cloneElement(item.icon as React.ReactElement, {
									sx: {
										width: 64,
										height: 64
									}
								})
							)
						}
						<Typography variant="h6">{item.title}</Typography>
					</Stack>
				</CardContent>

				<CardActions>
					<Button fullWidth>Acceder</Button>
				</CardActions>

			</Card>
		</Grid>
	);
}

export default OfficeItem;