import React from "react";
import { Grid } from "@mui/material";
import Page from "components/Page/Page";
import PageHeader from "components/Page/PageHeader";
import OfficeItem from "./OfficeItem";
import { theMenu } from "components/Page/Menu";
import { useNavigate } from "react-router-dom";

function HomePage () {
	const navigate = useNavigate();
	return (
		<Page>
			<PageHeader title="Back-offices" />
			<Grid container spacing={3}>
				{theMenu.map(item => (
					<OfficeItem
						key={item.path}
						item={item}
						navigate={navigate}
					/>
				))}
			</Grid>
		</Page>
	);
}

export default HomePage;