import React, { useState } from "react";
import { Snackbar, Alert, AlertColor } from "@mui/material";
import { useDisclose } from "hooks/discloseHook";
import ConfirmDialog from "./ConfirmDialog";
import AlertDialog from "./AlertDialog";

interface IAlertOptions {
	msg: string;
	severity: AlertColor;
}

interface IConfirmDialogOptions {
	title: string;
	msg: string;
	onConfirm: VoidFunction;
}

interface IAlertDialogOptions {
	title: string;
	msg: string;
	children?: JSX.Element;
}

interface IProps {
	children: React.ReactNode
}

interface IFeedbackContext {
	openAlert: (type: AlertColor, msg: string) => void;
	openConfirmDialog: (options: IConfirmDialogOptions) => void;
	openAlertDialog: (options: IAlertDialogOptions) => void;
}

const FeedbackContext = React.createContext<IFeedbackContext>({} as IFeedbackContext);

function FeedbackProvider ({ children }: IProps) {
	const [alertOptions, setAlertOptions] = useState<IAlertOptions>();
	const [isAlertOpen, openAlert, closeAlert] = useDisclose(false);

	const [confirmDialogOptions, setConfirmDialogOptions] = useState<IConfirmDialogOptions>();
	const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useDisclose(false);

	const [alertDialogOptions, setAlertDialogOptions] = useState<IAlertDialogOptions>();
	const [isAlertDialogOpen, openAlertDialog, closeAlertDialog] = useDisclose(false);

	const handleOpenAlert = (type: AlertColor, msg: string) => {
		setAlertOptions({
			msg,
			severity: type
		});
		openAlert();
	};

	const handleOpenConfirmDialog = (options: IConfirmDialogOptions) => {
		setConfirmDialogOptions(options);
		openConfirmDialog();
	};

	const handleOpenAlertDialog = (options: IAlertDialogOptions) => {
		setAlertDialogOptions(options);
		openAlertDialog();
	};

	const value: IFeedbackContext = {
		openAlert: handleOpenAlert,
		openConfirmDialog: handleOpenConfirmDialog,
		openAlertDialog: handleOpenAlertDialog
	};

	return (
		<FeedbackContext.Provider value={value}>
			{children}
			<Snackbar open={isAlertOpen} autoHideDuration={5000} onClose={closeAlert}>
				<Alert severity={alertOptions?.severity} onClose={closeAlert} sx={{ width: "100%" }}>
					{alertOptions?.msg}
				</Alert>
			</Snackbar>
			<ConfirmDialog
				isOpen={isConfirmDialogOpen}
				title={confirmDialogOptions?.title ?? "Erreur"}
				message={confirmDialogOptions?.msg ?? "Une erreur est survenue !"}
				onConfirm={confirmDialogOptions?.onConfirm ?? closeConfirmDialog}
				onClose={closeConfirmDialog}
			/>
			<AlertDialog
				isOpen={isAlertDialogOpen}
				title={alertDialogOptions?.title ?? "Erreur"}
				message={alertDialogOptions?.msg ?? "Une erreur est survenue !"}
				content={alertDialogOptions?.children}
				onClose={closeAlertDialog}
			/>
		</FeedbackContext.Provider>
	);
}

export function useFeedback () {
	return React.useContext(FeedbackContext);
}

export default FeedbackProvider;