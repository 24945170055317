import React from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import Navigator from "./components/Navigator/Navigator";
import { AuthProvider } from "./components/Provider/AuthProvider";
import FeedbackProvider from "components/Provider/Feedback/FeedbackProvider";

const theme = createTheme({
	palette: {
		mode: "dark",
	},
});

function App () {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<FeedbackProvider>
				<AuthProvider>
					<BrowserRouter>
						<Navigator />
					</BrowserRouter>
				</AuthProvider>
			</FeedbackProvider>
		</ThemeProvider>
	);
}

export default App;
