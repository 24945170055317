import React from "react";
import { Box, Collapse, Drawer, Avatar, List, ListItem, ListItemButton, ListItemText, ListItemAvatar, ListItemIcon } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HomeIcon from "@mui/icons-material/Home";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import KeyIcon from "@mui/icons-material/Key";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppsIcon from "@mui/icons-material/Apps";
import { useDisclose } from "hooks/discloseHook";
import { useNavigate } from "react-router-dom";

export interface MenuItem {
	title: string;
	icon: string | React.ReactNode
	path: string;
	subMenus?: MenuItem[]
}

const drawerWidth = 240;

export const theMenu: MenuItem[] = [
	{
		title: "Accueil",
		icon: <HomeIcon fontSize="large" />,
		path: "/"
	},
	{
		title: "AdmandevAuth",
		icon: <KeyIcon fontSize="large" />,
		path: "/auth-api",
		subMenus: [
			{
				title: "Applications",
				icon: <AppsIcon />,
				path: "/applications"
			},
			{
				title: "Comptes",
				icon: <AccountCircleIcon />,
				path: "/accounts"
			}
		]
	},
	{
		title: "Soirée défis",
		icon: require("../../assets/images/icons/soireedefis.png"),
		path: "/soiree-defis",
		subMenus: [
			{
				title: "Défis",
				icon: <FormatListBulletedIcon />,
				path: "/challenge"
			},
			{
				title: "Propositions",
				icon: <TipsAndUpdatesIcon />,
				path: "/proposal"
			},
			{
				title: "Gages",
				icon: <ChildCareIcon />,
				path: "/looser-challenge"
			}
		]
	}
];

function Menu () {
	const navigate = useNavigate();

	return (
		<Drawer
			variant="permanent"
			anchor="left"
			sx={{
				zIndex: 0,
				width: drawerWidth,
				"& .MuiDrawer-paper": {
					width: drawerWidth,
				},
			}}
		>
			<Box height={60} />
			<List>
				{
					theMenu.map(item => (
						<ItemView
							key={item.title}
							item={item}
							path={item.path}
							onNavigate={navigate}
						/>
					))
				}
			</List>
		</Drawer>
	);
}

interface IMenuItemProps {
	item: MenuItem,
	path: string;
	onNavigate: (path: string) => void
}

function ItemView ({ item, path, onNavigate }: IMenuItemProps) {
	const [isSubMenuOpen, , , toggleSubMenu] = useDisclose(false);

	const hasSubMenu = !!item.subMenus?.length;

	const handleNavigate = () => {
		onNavigate(path);
	};

	return (
		<React.Fragment>
			<ListItem disablePadding divider>
				<ListItemButton
					onClick={hasSubMenu ? toggleSubMenu : handleNavigate}
				>
					{
						typeof item.icon === "string" ? (
							<ListItemAvatar>
								<Avatar
									src={item.icon as string}
									sx={{
										width: 32,
										height: 32
									}}
								/>
							</ListItemAvatar>
						) : (
							<ListItemIcon>
								{item.icon}
							</ListItemIcon>
						)
					}
					<ListItemText primary={item.title} />
					{
						hasSubMenu && (
							isSubMenuOpen ? <ExpandLess /> : <ExpandMore />
						)
					}
				</ListItemButton>
			</ListItem>
			{
				hasSubMenu && (
					<Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
						<List sx={{ ml: 1 }}>
							{item.subMenus?.map(subItem => (
								<ItemView
									key={subItem.title}
									item={subItem}
									path={`${item.path}${subItem.path}`}
									onNavigate={onNavigate}
								/>
							))}
						</List>
					</Collapse>
				)
			}
		</React.Fragment>
	);
}

export default Menu;