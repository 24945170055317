import React, { useState } from "react";
import { IconButton, Menu, SxProps, Theme } from "@mui/material";

interface IProps {
	id: string;
	icon: JSX.Element;
	children: React.ReactNode;
	sx?: SxProps<Theme>;
	onIconClick?: () => void;
}

function IconMenu ({ id, icon, children, sx, onIconClick }: IProps) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);

		if (onIconClick) {
			onIconClick();
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<IconButton
				onClick={handleClick}
				size="small"
				sx={sx ?? { ml: 2 }}
				aria-controls={open ? `${id}-menu` : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
			>
				{icon}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
			>
				{children}
			</Menu>
		</React.Fragment>
	);
}

export default IconMenu;