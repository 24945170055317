import React, { useState, useEffect } from "react";
import { MenuItem, ListItemIcon, Stack, Tooltip, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Page from "components/Page/Page";
import { useFeedback } from "components/Provider/Feedback/FeedbackProvider";
import { Challenge } from "models/SoireeDefis/Challenge";
import { SDChallengeService } from "services/api/SoireeDefis/SDChallengeService";
import Table, { HeadCell } from "components/Table";
import IconMenu from "components/IconMenu";
import { useDisclose } from "hooks/discloseHook";
import ChallengeFormDialog from "../common/ChallengeFormDialog";
import { Utils } from "utils/Utils";

function SDChallengeListPage () {
	const [challenges, setChallenges] = useState<Challenge[]>([]);
	const [selectedChlges, setSelectedChlges] = useState<string[]>([]);
	const [isChlgeFormOpen, openChlgeForm, closeChlgeForm] = useDisclose(false);
	const [chlgeToEdit, setChlgeToEdit] = useState<Challenge | undefined>();

	const { openAlert, openConfirmDialog } = useFeedback();

	useEffect(() => {
		fetchChallenges();
	}, []);

	const fetchChallenges = async () => {
		try {
			const result = await SDChallengeService.getAll();

			if (result.isError) {
				throw result.message;
			}

			setChallenges(result.value.reverse());

		} catch (error) {
			openAlert("error", error as string);
		}
	};

	const handleSubmitNewChallenge = async (challenge: Challenge) => {
		try {
			const result = await SDChallengeService.create(challenge);
			if (result.isError) {
				throw result.message;
			}

			setChallenges(challenges => [result.value, ...challenges]);
			closeChlgeForm();
			openAlert("success", "Le défi a été créé avec succès !");

		} catch (error: unknown) {
			openAlert("error", error as string);
		}
	};

	const handleDeleteChallenge = (challengeId: string) => {
		const doDelete = async () => {
			try {
				const result = await SDChallengeService.delete(challengeId);
				if (result.isError) {
					throw result.message;
				}

				setChallenges(challenges => challenges.filter(c => c._id !== challengeId));
				openAlert("success", "Le défi a été supprimé avec succès !");

			} catch (error: unknown) {
				openAlert("error", error as string);
			}
		};

		openConfirmDialog({
			title: "Supprimer un défi",
			msg: "Êtes-vous sûr de vouloir supprimer ce défi ?",
			onConfirm: doDelete
		});
	};

	const handleOpenEditForm = (challenge: Challenge) => {
		setChlgeToEdit(challenge);
		openChlgeForm();
	};

	const handleEditChallenge = async (challenge: Challenge) => {
		try {
			const chlgeInx = challenges.findIndex(c => c._id === chlgeToEdit?._id);

			if (!chlgeToEdit || chlgeInx === -1) {
				throw "Une erreur est survenue !";
			}

			const result = await SDChallengeService.update(chlgeToEdit._id, challenge);
			if (result.isError) {
				throw result.message;
			}

			setChallenges(challenges => {
				const copyChallenges = [...challenges];
				copyChallenges[chlgeInx] = result.value;

				return copyChallenges;
			});

			closeChlgeForm();
			openAlert("success", "Le défi a été mis à jour !");

		} catch (error: unknown) {
			openAlert("error", error as string);
		}
	};

	const handleExportChallenges = () => {
		const chlgesToExport = challenges.filter(c => selectedChlges.includes(c._id));
		Utils.exportData(JSON.stringify(chlgesToExport), "Challenges.json");
	};

	const tableColumns: HeadCell[] = [
		{
			id: "description",
			label: "Description",
		},
		{
			id: "type",
			label: "Type",
		},
		{
			id: "categories",
			label: "Catégories",
			render: (row: Challenge) => row.categories.map(c => c.name).join(" | ")
		},
		{
			id: "winPoints",
			label: "Bonus",
			numeric: true,
			disablePadding: true,
			render: (row: Challenge) => `+${row.winPoints}`
		},
		{
			id: "loosePoints",
			label: "Malus",
			numeric: true,
			disablePadding: true,
			render: (row: Challenge) => `-${row.loosePoints}`
		},
		{
			id: "minuteTime",
			label: "Temps",
			numeric: true,
			disablePadding: true
		},
		{
			id: "action-menu",
			label: "",
			render: (row: Challenge) => (
				<IconMenu
					id={`challenge-item-menu-${row._id}`}
					icon={<MoreVertIcon />}
				>
					<MenuItem divider onClick={() => handleOpenEditForm(row)}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						Modifier
					</MenuItem>
					<MenuItem onClick={() => handleDeleteChallenge(row._id)}>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						Supprimer
					</MenuItem>
				</IconMenu>
			),
		}
	];

	const getToolActions = () => {
		const handleOpenChlgeForm  = () => {
			setChlgeToEdit(undefined);
			openChlgeForm();
		};

		return (
			<Stack direction="row" spacing={2}>
				<Tooltip title="Nouveau défi">
					<IconButton onClick={handleOpenChlgeForm}>
						<AddIcon />
					</IconButton>
				</Tooltip>
			</Stack>
		);
	};

	const getItemGroupActions = () => {
		return (
			<Stack direction="row" spacing={2}>
				<Tooltip title="Export au format json">
					<IconButton onClick={handleExportChallenges}>
						<FileDownloadIcon />
					</IconButton>
				</Tooltip>
			</Stack>
		);
	};

	return (
		<Page>
			<Table
				title="Les défis"
				columns={tableColumns}
				data={challenges}
				rowIdentifier={(row: Challenge) => row._id}
				toolAction={getToolActions()}
				itemGroupAction={getItemGroupActions()}
				onSelectedChanged={setSelectedChlges}
			/>
			<ChallengeFormDialog
				title={chlgeToEdit ? "Modifier un défi" : "Nouveau défi"}
				isOpen={isChlgeFormOpen}
				initialValue={chlgeToEdit}
				onClose={closeChlgeForm}
				onSubmit={chlgeToEdit ? handleEditChallenge : handleSubmitNewChallenge}
			/>
		</Page>
	);
}

export default SDChallengeListPage;