import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import HomePage from "pages/Admanoffice/Home/HomePage";
import LoginPage from "../../pages/Admanoffice/Login/LoginPage";
import SDChallengeListPage from "pages/SoireeDefies/ChallengeList/SDChallengeListPage";
import SDProposalPage from "pages/SoireeDefies/Proposal/SDProposalPage";
import SDLooserChallengePage from "pages/SoireeDefies/LooserChallenge/SDLooserChallengePage";
import AAAcountsPage from "pages/AdmandevAuthAPI/Account/AAAcountsPage";
import ApplicationsPage from "pages/AdmandevAuthAPI/App/ApplicationsPage";

function Navigator () {
	return (
		<React.Fragment>
			<Routes>
				<Route index element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
				<Route path="login" element={<LoginPage />} />
				<Route path="soiree-defis">
					<Route path="challenge" element={<ProtectedRoute><SDChallengeListPage /></ProtectedRoute>} />
					<Route path="proposal" element={<ProtectedRoute><SDProposalPage /></ProtectedRoute>} />
					<Route path="looser-challenge" element={<ProtectedRoute><SDLooserChallengePage /></ProtectedRoute>} />
				</Route>
				<Route path="auth-api">
					<Route path="accounts" element={<ProtectedRoute><AAAcountsPage /></ProtectedRoute>} />
					<Route path="applications" element={<ProtectedRoute><ApplicationsPage /></ProtectedRoute>} />
				</Route>
			</Routes>
		</React.Fragment>
	);
}

export default Navigator;
