import React from "react";
import { AppBar, Box, Toolbar, Typography, Stack, MenuItem, Avatar, ListItemIcon } from "@mui/material";
import { useAuth } from "../Provider/AuthProvider";
import Menu from "./Menu";
import IconMenu from "components/IconMenu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

interface IProps {
	children: React.ReactNode;
}

function Page ({ children }: IProps) {
	const { isLogged, logout } = useAuth();

	const navigate = useNavigate();

	const handleLogout = async () => {
		await logout();
		navigate("/login");
	};

	return (
		<Box display="flex" flex={1}>
			<AppBar position="fixed">
				<Toolbar>
					<Typography flexGrow={1} variant="h5">Admanoffice</Typography>
					{
						isLogged && (
							<IconMenu
								id="account-menu"
								icon={<Avatar />}
							>
								<MenuItem onClick={handleLogout}>
									<ListItemIcon>
										<LogoutIcon />
									</ListItemIcon>
									Déconnexion
								</MenuItem>
							</IconMenu>
						)
					}
				</Toolbar>
			</AppBar>
			<Stack direction="row" mt={9} flex={1}>
				{isLogged && <Menu />}
				<Box
					sx={{
						width: "100%",
						height: "100%",
						p: 2
					}}
				>
					{children}
				</Box>
			</Stack>
		</Box>
	);
}

export default Page;