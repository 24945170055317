import React, { useState, useEffect } from "react";
import { Stack, Container, Collapse, Tooltip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Page from "components/Page/Page";
import SDProposalItem from "./SDProposalItem";
import { ChallengeProposal } from "models/SoireeDefis/ChallengeProposal";
import { useFeedback } from "components/Provider/Feedback/FeedbackProvider";
import { SDProposalService } from "services/api/SoireeDefis/SDProposalService";
import PageHeader from "components/Page/PageHeader";
import ChallengeFormDialog from "../common/ChallengeFormDialog";
import { useDisclose } from "hooks/discloseHook";
import { Challenge } from "models/SoireeDefis/Challenge";

function SDProposalPage () {
	const [proposals, setProposals] = useState<ChallengeProposal[]>([]);
	const [hiddenProposalIds, setHiddenProposalIds] = useState<string[]>([]);
	const [isChlgeFormOpen, openChlgeForm, closeChlgeForm] = useDisclose(false);
	const [proposalToPublish, setProposalToPublish] = useState<ChallengeProposal>();

	const { openAlert, openConfirmDialog } = useFeedback();

	useEffect(() => {
		fetchProposals();
	}, []);

	const fetchProposals = async () => {
		try {
			const result = await SDProposalService.getAll();

			if (result.isError) {
				throw result.message;
			}

			setProposals(result.value);

		} catch (error) {
			openAlert("error", error as string);
		}
	};

	const handleOpenCreateProposalForm = () => {
		setProposalToPublish(undefined);
		openChlgeForm();
	};

	const handleOpenPublishProposalForm = (proposal: ChallengeProposal) => {
		setProposalToPublish(proposal);
		openChlgeForm();
	};

	const handleCreateProposal = async (challenge: Challenge) => {
		try {
			const result = await SDProposalService.create(challenge);

			if (result.isError) {
				throw result.message;
			}

			setProposals(proposals => [result.value, ...proposals]);
			closeChlgeForm();

			openAlert("success", "La proposition ajoutée !");

		} catch (error) {
			openAlert("error", error as string);
		}
	};

	const handleDeleteProposal = (proposalId: string) => {
		const doDelete = async () => {
			try {
				const result = await SDProposalService.delete(proposalId);

				if (result.isError) {
					throw result.message;
				}

				setHiddenProposalIds(proposals => [...proposals, proposalId]);

				openAlert("success", "La proposition a été supprimée !");

			} catch (error) {
				openAlert("error", error as string);
			}
		};

		openConfirmDialog({
			title: "Supprimer cette proposition",
			msg: "Êtes-vous sûr de vouloir supprimer cette proposition ?",
			onConfirm: doDelete
		});
	};

	const handlePublishProposal = async (challenge: Challenge) => {
		try {
			const proposalId = proposalToPublish?._id;
			if (!proposalId) {
				throw "La proposition séléctionnée n'a pas été trouvé !";
			}

			const result = await SDProposalService.publish(proposalId, challenge);

			if (result.isError) {
				throw result.message;
			}

			setHiddenProposalIds(proposals => [...proposals, proposalId]);
			closeChlgeForm();

			openAlert("success", "La proposition a été publiée !");

		} catch (error) {
			openAlert("error", error as string);
		}
	};

	return (
		<Page>
			<PageHeader
				title="Les propositions"
				tools={
					<>
						<Tooltip title="Nouvelle proposition">
							<IconButton onClick={handleOpenCreateProposalForm}>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</>
				}
			/>
			<Container maxWidth="sm">
				<Stack spacing={2} justifyContent="center">
					{proposals.map(p => (
						<Collapse key={p._id} in={!hiddenProposalIds.includes(p._id)}>
							<SDProposalItem
								item={p}
								onDelete={handleDeleteProposal}
								onPublish={handleOpenPublishProposalForm}
							/>
						</Collapse>
					))}
				</Stack>
			</Container>
			<ChallengeFormDialog
				title={proposalToPublish ? "Publier une proposition" : "Nouvelle proposition"}
				isOpen={isChlgeFormOpen}
				initialValue={proposalToPublish?.challenge}
				hideOptional={!proposalToPublish}
				onClose={closeChlgeForm}
				onSubmit={proposalToPublish ? handlePublishProposal : handleCreateProposal}
			/>
		</Page>
	);
}

export default SDProposalPage;