import { RequestResponse } from "models/SoireeDefis/requests/RequestResponse";
import { Challenge } from "models/SoireeDefis/Challenge";
import { AxiosConfigurator } from "services/AxiosConfigurator";

const axios = AxiosConfigurator.getAxiosInstance("sd/challenge");

/** 
 * Defines the Soirée défis challenge service 
 */
export class SDChallengeService {
	/** 
	 * Get all challenges
	 * 
	 * @returns {Promise<RequestResponse<Challenge[]>>} The challenge list 
	 */
	public static async getAll () {
		const response = await axios.get<RequestResponse<Challenge[]>>("/");
		return response.data;
	}

	/** 
	 * Create a new challenge
	 * 
	 * @param {Challenge} challenge The challenge to create
	 * @returns {Promise<RequestResponse<Challenge>>} The created challenge
	 */
	public static async create (challenge: Challenge) {
		const response = await axios.post<RequestResponse<Challenge>>("/", challenge);
		return response.data;
	}

	/**
	 * Update a new challenge
	 * 
	 * @param {string} id The Id of challenge to delete
	 * @param {Challenge} challenge The challenge to create
	 * @returns {Promise<RequestResponse<Challenge>>} The updated challenge
	 */
	public static async update (id: string, challenge: Challenge) {
		const response = await axios.put<RequestResponse<Challenge>>(`/${id}`, challenge);
		return response.data;
	}

	/**
	 * Delete a challenge
	 * 
	 * @param {string} challengeId The Id of challenge to delete
	 * @returns {Promise<RequestResponse<null>>} The response
	 */
	public static async delete (challengeId: string) {
		const response = await axios.delete<RequestResponse<Challenge>>(`/${challengeId}`);
		return response.data;
	}
}